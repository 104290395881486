<template>

<div>

	<com-title text="Organisations" />

	<com-item route="Organisations.Browse" text="Browse" />
	<com-item route="Organisations.Signups" text="Signups" />

</div>

</template>

<script>

export default {

	components: {
		'com-title': () => import('./../../panel/common/Title'),
		'com-item': () => import('./../../panel/common/Item')
	}

}

</script>

<style scoped>

</style>
